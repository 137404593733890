@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  *,
  ::before,
  ::after {
    box-sizing: border-box; /* 1 */
    border-width: 0; /* 2 */
    border-style: solid; /* 2 */
    border-color: theme('borderColor.DEFAULT', currentColor); /* 2 */
  }
  
  ::before,
  ::after {
    --tw-content: '';
  }
}

.dot-grid {
  background: #374151;
  background-image: 
    linear-gradient(#374151 22px, transparent 0),
    linear-gradient(90deg, #6b7280 2px, transparent 0);
  background-size: 24px 24px;
}

*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(144, 147, 153, 0.3);
}

*::-webkit-scrollbar-track {
  background: rgba(144, 147, 153, 0.1);
}
